<template>
    <div class="protocol">
        <h1>隐私权政策</h1>
        <p>我们深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。 请在使用我们的产品（或服务）前，仔细阅读并了解本《隐私权政策》。</p>

    <h2>一、我们如何收集和使用您的个人信息</h2>
    <p>个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</p> 
    <p>个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</p> 
    <p>我们仅会出于本政策所述的以下目的，收集和使用您的个人信息：</p> 

    <p>（一）为您提供网上购物服务
        1、业务功能一：注册成为用户
        为完成创建账号，您需提供以下信息：您的姓名、电子邮箱地址、创建的用户名和密码、……。
        在注册过程中，如果您提供以下额外信息，将有助于我们给您提供更好的服务和体验：手机号，姓名……。但如果您不提供这些信息，将不会影响使用本服务的基本功能。
        您提供的上述信息，将在您使用本服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除上述信息。
        上述信息将存储于中华人民共和国境内。如需跨境传输，我们将会单独征得您的授权同意。
    </p>
   <p> 2、业务功能二：商品展示、个性化推荐、发送促销营销信息。<br>
        （二）开展内部数据分析和研究，第三方SDK统计服务，改善我们的产品或服务
        我们收集数据是根据您与我们的互动和您所做出的选择，包括您的隐私设置以及您使用的产品和功能。我们收集的数据可能包括SDK/API/JS代码版本、浏览器、互联网服务提供商、IP地址、平台、时间戳、应用标识符、应用程序版本、应用分发渠道、独立设备标识符、iOS广告标识符（IDFA）、安卓广告主标识符、网卡（MAC）地址、国际移动设备识别码（IMEI）、设备型号、终端制造厂商、终端设备操作系统版本、会话启动/停止时间、语言所在地、时区和网络状态（WiFi等）、硬盘、CPU和电池使用情况等。 当我们要将信息用于本策略未载明的其它用途时，会事先征求您的同意。
        当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。
    </p> 

    <h2>二、我们如何使用 Cookie 和同类技术</h2>
 <p>（一）Cookie</p>
<p>为确保网站正常运转，我们会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于 Cookie，网站能够存储您的偏好或购物篮内的商品等数据。
我们不会将 Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。如需详细了解如何更改浏览器设置，请访问以下链接：</p>

 <p>（二）网站信标和像素标签</p>
<p>除 Cookie 外，我们还会在网站上使用网站信标和像素标签等其他同类技术。
例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击 URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。</p>

<p>（三）Do Not Track（请勿追踪）</p>
<p>很多网络浏览器均设有 Do Not Track 功能，该功能可向网站发布 Do Not Track 请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了 Do Not Track，那么我们的所有网站都会尊重您的选择。</p>

<h2>三、我们如何共享、转让、公开披露您的个人信息</h2>
<p>（一）共享</p>
<p>我们不会向其他任何公司、组织和个人分享您的个人信息，但以下情况除外：<br>
    1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。<br>
    2、我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。<br>
    3、与我们的关联公司共享：您的个人信息可能会与我们关联公司共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。<br>
    4、与授权合作伙伴共享：仅为实现本隐私权政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如，我们聘请来提供第三方数据统计和分析服务的公司可能需要采集和访问个人数据以进行数据统计和分析。在这种情况下，这些公司 必须遵守我们的数据隐私和安全要求。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。<br>
    对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
</p>

<p>（二）转让</p>

<p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
2、在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</p>

<p>（三）公开披露</p>

<p>我们仅会在以下情况下，公开披露您的个人信息：
    1、获得您明确同意后；
    2、基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
</p>
<h2> 四、我们如何保护您的个人信息</h2>
<p>（一）我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在您的浏览器与“服务”之间交换数据（如信用卡信息）时受 SSL 加密保护；我们同时对我们网站提供 https 安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</p>

<p>（二）我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</p>

<p>（三）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他我们用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。</p>

<p>（四）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</p>

<p>（五）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。</p>

<p>同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>

<h2>五、您的权利</h2>
<p> 按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</p>

<p>（一）访问您的个人信息</p>
<p>您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：登陆应用，在个人中心-个人资料处访问。
如果您无法通过上述链接访问这些个人信息，您可以随时联系我们的系统客服，我们将在30天内回复您的访问请求。
对于您在使用我们的产品或服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供。如果您想行使数据访问权，请联系官方客服。
</p>
<p>（二）更正您的个人信息</p>
<p>当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。您可以通过“（一）访问您的个人信息”中的方式进行更改。
如果您无法通过上述方式更正这些个人信息，您可以随时与官方客服联系，我们将在30天内回复您的更正请求。</p>

<p>（三）删除您的个人信息</p>
<p>在以下情形中，您可以向我们提出删除个人信息的请求：<br>
1、如果我们处理个人信息的行为违反法律法规；<br>
2、如果我们收集、使用您的个人信息，却未征得您的同意；<br>
3、如果我们处理个人信息的行为违反了与您的约定；<br>
4、如果您不再使用我们的产品或服务，或您注销了账号；<br>
5、如果我们不再为您提供产品或服务。<br>
若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。</p>

<p>（四）改变您授权同意的范围</p>
<p>每个业务功能需要一些基本的个人信息才能得以完成（见本策略“第一部分”）。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。
当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。</p>

<p>（五）个人信息主体获取个人信息副本</p>
<p>您有权获取您的个人信息副本，在技术可行的前提下，例如数据接口匹配，我们还可按您的要求，直接将您的个人信息副本传输给您指定的第三方。</p>

<p>（六）约束信息系统自动决策</p>
<p>在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。</p>

<p>（七）响应您的上述请求</p>
<p>为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
我们将在三十天内做出答复。
对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
在以下情形中，按照法律法规要求，我们将无法响应您的请求：<br>
1、与国家安全、国防安全有关的；<br>
2、与公共安全、公共卫生、重大公共利益有关的；<br>
3、与犯罪侦查、起诉和审判等有关的；<br>
4、有充分证据表明您存在主观恶意或滥用权利的；<br>
5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。</p>

<h2>六、本隐私权政策如何更新</h2>
我们可能适时会对本隐私权政策进行调整或变更，本隐私权政策的任何更新将以标注更新时间的方式公布在我们网站上，除法律法规或监管规定另有强制性规定外，经调整或变更的内容一经通知或公布后的7日后生效。如您在隐私权政策调整或变更后继续使用我们提供的任一服务或访问我们相关网站的，我们相信这代表您已充分阅读、理解并接受修改后的隐私权政策并受其约束。

<h2>七、如何联系我们</h2>
<p>如果您对本隐私政策有任何疑问、意见或建议，可与我们的官方客服联系 一般情况下，我们将在三十天内回复。</p>

<h1>消息查询协议</h1>
<p>如您选择勾选"使用本服务意味着您已阅读并同意《信息查询协议》"将视为授权"我司"向合法的第三方支付机构/第三方征信机构/金融机构等(以下简称"第三方") 提交您的个人信用査询申请, 本公司根据查询结果汇总生成您的相关个人信用报告。 您同意本公司«授权协议»服务条款,内容如下:</p>

<h2> 一、授权服务条款:</h2>
<p>1、本公司报告均需由本人授权查询,依据截止提交查询申请的时间查询所得结果汇总生成该个人报告, 本公司有义务为您保密个人报告, 在未经得您本人的授权同意的情况下不会向任何第三方泄露您的相关信息。</p>

<p>2、您理解,为了给您提供客观、科学、全面的管理及评价,以及其他本协议项下服务的需要,您授权我们可以从合法保存有您信息的第三方, 采集及处理您的各类信息,同时为避免每次采集都需要经过您的反复确认而导致过程繁杂,或者因此给您带来的不便,您同意第三方可直接向我们提供您的信息而不需要您再次授权。采集您的信息包括但不限于您的个人信息、行为信息、交易信息、资产信息、设备信息等,目前采集后展示信息包括:芝麻信用相关, 芝麻分、芝麻信用欺诈风险项; 运营商相关数据, 实名核对、入网时长、 号码归属地、 联系人黑名分析、 手机静默天数、互通号码个数、异常号码显示、活跃地区、夜间通话情况分析、 半年通话消费账単、 主/被叫次数、主/被叫分钟数、 常联系人名单; 多平台申请借贷次数检测、多平台借贷是否有逾期(不会获取金额、放贷公司名称和借款记录) 、 是否被借贷平台列入黑名単、 曾经申请借贷是否多次填错个人信息; 公积金相关信息,个人客户号、姓名、身份证号、移动电话号码、电子邮箱(上述五项已带码不会获取全部信息)、公积金余额、缴纳时间;学信相关信息,姓名、身份证、学号(以上三项已带码不会获取全部信息)、学生照、性别、出生日期、民族、院校名称、所学专业、班级、学制、学历、入学及高校时间;京东数据,实名、认证身份证、绑定手机号、邮箱、收货人、地址、收货电话(上述七项已带码不会获取全部信息)、京东用户名、用户级别、用户昵称、性别、京东信用分等。您已经认识到您(作为个人用户)的收入、存款、有价证券、商业保险、不动产的信息和纳税数额信息对于您而言是相当私密而重要的。</p>

<p>3、您同意我们向第三方采集并在适用的法律法规许可的范围内向信息使用者依法提供这些信息时, 您已经充分理解并自愿该等信息被提供和使用的风险。这些风险包括但不限于:纳入这些信息对您的个人信用评级(评分)、个人信用报告等结果可能产生的不利影响,该等信息被本公司依法提供给第三方后被他人不当利用的风险,因您的个人信用状况较好而造成您被第三方向您推销产品或服务等打扰的风险。</p>

<p>4、您同意可直接向本公司提供信息的第三方包括但不限于:金融机构、类金融机构、电子商务公司、电信运营商、 政府公共个人信用信息平台及事业单位等一切盈利性、非盈利性、公共、民间的机构及组织。同时我们向您承诺,我们不会采集您的宗教信仰、基因、指纹、血型、疾病和病史信息以及适用的法律法规禁止采集的任何个人信息,同时我们也不会采集您的聊天内容、短信内容、通话内容等个人信息,也不会采集、 追踪您在社交媒体上的言论信息 。</p>

<p>5、您理解并同意,就此服务而言,当第三方向我们查询您的相关信息时, 该第三方应当获得您的事先授权同意并在约定范围使用。但是法律规定可以不经您授权即可查询的除外。因此,当您在相应场景下希望我们根据您的授权向第三方提供您的信息时, 您应当向第三方做出清楚明确的授权, 否则可能导致我们拒绝提供相应服务,或错误提供该等服务,因此可能会给您造成不利影响或损失。您充分理解并同意此结果的发生是由于您授权不清或不够明确导致,所以您将承担相应的后果。</p>

<p>6、您理解并同意,您同意第三方查询您的非贷款类及其他非涉及商业秘密信息时,我们可以直接向第三方提供您的相关信息。</p>

<p>7、为降低您身份被冒用及您关联非本人风险事件而影响您享受有关服务的风险, 您同意我们在收到第三方提供的您的信息时,授权我们及我们的合作机构可通过您所留存的信息进行对比分析,直接向该第三方输出您的信息是否真实、准确、有效,以及是否存在关联风险事件的判断结果信息。但基于保护您个人信息的需要,在提供本款服务过程中,无论是我们还是我们的合作机构都不会在任何情况下输出除判断结果信息外的您的其他信息。</p>

<p>8、我们善意的提醒您,为了保护您免受身份冒用或者因为关联非本人风险事件而遭受损失,您在第三方的注册、登录或使用相关服务过程中, 第三方可能拒绝给您提供有关优恵条件或暂停、终止向您提供服务。如您因此受到上述影响, 本公司无须就此承担责任。</p>

<p>9、 您理解并同意, 如您使用了提现或支付功能, 则应当依据«借款协议» 或其他同等性质的融资文件中约定之日期按期进行还款, 本公司有权选择通过短信、电话、社交账号等途径对您进行服务与还款提醒。您理解并同意,如您未有按期还款,您的个人逾期信息将可能向第三方(包括但不限于征信机构、金融机构、公众媒体等)进行分享或公布,记录在您的个人信用信息数据库中,对此造成的不良后果您已知悉。 请您珍视自身的个人信用记录, 按时履行还款义务。</p>

<p>10、您理解并同意,您应当对您的信息变动履行及时告知义务。若您的联系方式发生变化,应及时通知本公司,否则由此带来的影响和损失,本公司不承担责任。</p>
<h2>二、保密条款:</h2>
<p>（一）本公司重视对用户隐私的保护。因收集您的信息是出于遵守国家法律法规的规定以及向您提供服务及提升服务质量的目的, 本公司对您的个人信息承担保密义务, 不会为满足第三方的营销目的而向其出售或出租您的任何信息。</p>

<p>（二）本公司会在下列情况下才将您的个人信用信息与第三方共享: </p>

<p>1、事先获得用户的明确授权。 </p>

<p>2、某些情况下,只有共享您的信息,才能提供您需要的服务和(或)产品,或处理您与他人的交易纠纷或争议。</p>

<p>3、某些服务和(或)产品由本公司的合作伙伴提供或由本公司与合作伙伴共同提供,本公司会与其共享提供服务和(或)产品需要的信息。 </p>

<p>4、本公司与第三方进行联合推广活动,本公司可能与其共享活动过程中产生的、为完成活动所必要的个人信息, 如参加活动的中奖名単、 中奖人联系方式等, 以便第三方能及时向您发放奖品。 </p>

<p>5、为维护本公司和其他査询用户的合法权益。 </p>

<p>6、根据法律规定及合理商业习惯, 在本公司计划与其他公司合并或被其收购或进行其他资本市场活动(包括但不限于债券发行)时, 以及其他情形下本公司需要接受来自其他主体的尽职调查时,本公司会把您的信息提供给必要的主体, 但本公司会通过和这些主体益署保密协议等方式要求其对您的个人信息采取合理的保密措施。 根据有关的法律法规要求。 </p>

<p>7、按照相关政府主管部门的要求。 </p>

<p>8、由于用户将其用户密码告知他人或与他人共享注册账户与密码, 由此导致的任何个人信息的世漏, 或其他非因本公司原因导致用户个人信息的泄露。 </p>

<p>9、任何由于黑客攻击、 电脑病毒入侵及其他不可抗力事件所导致的用户信息,、世露、 公开。</p>

<h2>三、服务限制及终止</h2>
<p>1、您同意如您违反以下保证或发生相关情形,我们将有权限制或终止向您提供本协议项下的部分或全部服务 。</p>

<p>2、您是个人用户且向我们保证您已经年满1 8周岁, 并具备完全民事行为能力 。</p>

<p>3、您不可以在任何时候将我们的服务用于任何非法目的, 比如侵害他人合法权益, 或者以非法方式使用我们的服务 。</p>

<p>4、您保证,在我们为您提供服务期间,您向我们提供的所有资料和信息都是真实、完整、准确的并能够及时更新。 为了保障为您提供有效完整的服务, 您同意在我们发现您的身份存疑或涉嫌冒用他人身份信息时,有权基于保主户您及(或)其他第三方合理利益的需要,要求您配合补全或更新相关身份信息及资料。</p>

<p>5、您保证,您不会授权他人使用我们的服务。比如您将使用本协议项下服务所需的载体,包括但不限于用于登录的账户、手机等披露或提供给其他人代替您操作、使用我们的服务。我们善意的提醒您,上述操作可能导致他人冒用您的身份使用本协议项下服务, 因此给您造成损失或导致不良行为记录。</p>

<p>6、如果我们认为您没有遵守本协议的约定,或者我们认为继续为您提供服务将会导致我们或第三方的合理利益受到损失。</p>

<p>7、服务终止后, 为了后续异议或纠纷处理及未来查询相关信息的需要,从而保障我们及您的合法权益, 您同意在服务終止后, 我们仍可继续保留您使用我们的服务期间形成的所有信息和数据, 但我们不会再主动采集您的任何信息。</p>

<h2>四、服务中断或故障:</h2>
<p>我们的系统在下列状况发生时会无法正常运作, 所以下列情形中我们无法向您提供服务。您对此充分理解并同意我们无须就下述情况所导致服务无法正常提供时给您造成的各种影响或损失承担责任: </p>

<p>1、我们公告的系统停机维护期间。</p>

<p>2、电信设备出现故障不能进行数据传输的。</p>

<p>3、发生台风、地震、海口肃、洪水、停电、战争、恐怖袭击等事件造成我们系统障碍不能执行业务的。</p>

<p>4、由于黑客攻击、电信部门技术调整或故障、网站升级、 第三方方面的问题等原因而造成我们的服务中断或者延迟。</p>

<h2>五、责任限制:</h2>
<p>您理解, 如因您主动授权第三方查询您的信息, 从而导致第三方拒绝向您提供服务或做出了对您不利的决定时,考虑到该信息的提供是由您自主同意提供的, 您同意我们无须就此承担责任或赔偿。</p>

<h2>六、协议变更:</h2>
<p>作为本协议服务的提供方,我们将根据国家法律、法规及其他规范性文件的规定变化,增加、变更了新的服务类型或服务方式, 进一步明确协议双方的权利义务的需要对本协议进行调整变更。 同时, 您同意未来我们可以通过我们或合作火伴的网站、 客户端或其他适当的地方进行公布的方式通知您, 而不需要另行单独通知您协议发生变更。您在本协议内容公告变更后继续使用本服务, 表示您已充分阅读、 理解并接受变更后的协议内容;若您不同意修改后的协议内容, 您应停止使用本服务。</p>

<h2>七、法律适用与管辖:</h2>
<p>本协议相关的所有问题均受到中国大陆法律管辖。如果因为本协议产生任何争议, 您同意由被告住所地人民法院管辖及裁决。</p>


    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    }
}
</script>

<style lang="less" scoped>
    .protocol{
        padding: 0 24px;
        color: #666;
    }
    h1{
        text-align: center;
        padding: 30px 0px;
    }
    h2{
        font-size: 30px;
    }
    p{
        font-size: 26px;
        margin:10px 0 20px 0;
        line-height: 40px;
    }
</style>